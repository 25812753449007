// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery-easing");
require("bootstrap");
const yall = require('yall-js').default;
document.addEventListener("DOMContentLoaded", yall);

window.$ = $;
import 'i18n-js/index.js.erb';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "../src/style.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "@fortawesome/fontawesome-free/js/all";
import 'moment';
import 'daterangepicker';


import { admin } from './admin';
import { users } from 'lib/users';
import { patientsShare } from 'lib/patients_share';
import { manageOrganizations } from 'lib/organizations';
import { patients } from 'lib/patients';
import { patientsWatch } from 'lib/patients_watch';
import { checkAll } from 'lib/check_all';
import { dateRangePicker } from 'lib/date_range_picker';

(function($) {
  "use strict"; // Start of use strict
  $(document).ready(function(){
    admin($);
    users();
    patientsShare();
    manageOrganizations();
    patients();
    checkAll();
    dateRangePicker();
  });
  $(window).on('pageshow', function(){
    patientsWatch();
  });
})(jQuery); // End of use strict
