export function patientsShare() {
  // Share action by ajax
  $('#form-patient-share').on('ajax:success', function(event){
    var data = event.detail[0];
    $('#patients-shares-zero').hide();
    var table = $('#patients-shares-table');
    table.removeClass('d-none');
    var tbody = table.find('tbody');
    for(let email in data){
      var row = $(data[email]);
      tbody.find('tr[data-email="' + email + '"]').remove();
      row.hide();
      tbody.prepend(row);
      row.fadeIn();
    }
  });

  // Refresh shares table
  $(document).on('ajax:success', '.refresh-shares-table', function(event){
    var data = event.detail[0];
    var table = $('#patients-shares-table');
    var container = table.parent();
    table.fadeOut(function(){
      table.remove();
      var new_table = $(data['table']);
      new_table.hide();
      container.append(new_table);
      new_table.fadeIn();
    });
  });

  // Unshare
  $(document).on('ajax:success', '#patients-shares-table .unshare', function(event){
    var data = event.detail[0];
    var table_row = $('tr[data-id="share-' + data['id'] + '"]');
    table_row.fadeOut(function(){ table_row.remove(); });
  });
}
