export function patientsWatch(bootstrap) {
  var patient_ids = $('[data-patient-watched]').map(function(){
    return this.dataset.patientWatched;
  }).toArray();
  if(patient_ids.length > 0){
    $.ajax({
      method: 'GET',
      url: '/s/p/watched_status.json',
      data: { ids: patient_ids },
      cache: false
    }).done(function(data){
      for(let patient_id in data){
        $('[data-patient-watched=' + patient_id + ']').each(function(){
          var badge = $(this);
          var sts = data[patient_id];
          if(sts == 'watched'){
            badge.removeClass();
            badge.empty();
          }else{
            badge.addClass('position-absolute top-0 start-100 translate-middle');
            badge.addClass('p-1 border border-light rounded-circle');
            if(sts == 'unwatched'){
              badge.addClass('bg-danger');
              badge.html('<span class="visually-hidden">New</span>');
            }else if(sts == 'updated'){
              badge.addClass('bg-warning');
              badge.html('<span class="visually-hidden">Updated</span>');
            }
          }
        });
      }
    });
  }
}
