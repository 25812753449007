import 'viewerjs/dist/viewer.css';
import ViewerJs from 'viewerjs';
import videojs from 'video.js';

export function admin($) {
  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
  });

  // Table row clickable
  $(document).on('click', '.table-row-clickable tbody tr', function(e) {
    var href = $(this).data('href');
    if(href){
      window.location = href;
    }
  });

  $(document).ready(function(){
    // Viwer.js for Picture
    $('.viewerjs').each(function(){
      const viewer = new ViewerJs(this);
    });

    // Video.js for Movies
    $.each($('.video-js'), function(){
      var player = videojs(this);
      player.on('error', function(){
        var error = player.error();
        console.log(error);
        if(error.code == MediaError.MEDIA_ERR_NETWORK){
          var ans = confirm(I18n.t('js.video.expired_error'))
          if(ans){
            location.reload();
          }
        }
      });
    });
  });
}
