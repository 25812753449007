export function dateRangePicker() {
    $('.date-range-picker').each(function() {
        const start_date = $(this).data('startDate');
        const end_date = $(this).data('endDate');
        const date_format = $(this).data('dateFormat');
        
        $(this).daterangepicker({
            autoUpdateInput: false,
            startDate: start_date,
            endDate: end_date,
            locale: {
                format: date_format
            }
        }).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format(date_format) + ' - ' + picker.endDate.format(date_format));
        });
    });
}
