import { Modal } from 'bootstrap';

export function patients(bootstrap) {
  $('.medical-datum-modal').each(function(){
    const modal = this;
    modal.addEventListener('show.bs.modal', function(event){
      const url = $(modal).data('remote');
      $.ajax({
        url: url
      }).done(function(data){
        $(modal).empty().append(data['modal']);
      });
    });
  });

  $(document).on('ajax:success', '.medical-datum-modal form', function(event){
    var modal = Modal.getInstance(event.target.parentElement.parentElement);
    modal.hide();
  });

  $('.my-note-update-form').each(function(){
    const form = $(this);
    const submit = form.find('.btn-save');
    const content = form.find('textarea[name=content]');
    const visit_id = form.data('visitId');
    var saved_note = content.val();
    function update_saved_status() {
      const unsaved = (saved_note == content.val());
      const handler = $("#my-note-handler-" + visit_id);
      const badge = $("#my-note-handler-" + visit_id + "-badge");
      submit.attr('disabled', unsaved);
      if(unsaved){
        badge.addClass('d-none');
        if(saved_note){
          handler.removeClass('text-white');
          handler.addClass('text-warning');
        }else{
          handler.addClass('text-white');
          handler.removeClass('text-warning');
        }
      }else{
        badge.removeClass('d-none');
      }
    }
    content.on('keyup', function(){
      update_saved_status();
    });
    form.on('ajax:success', function(event){
      const ret = event.detail[0];
      if(ret.status == 'OK'){
        saved_note = ret.content;
        update_saved_status();
      }
    });
    update_saved_status();
  });
}
