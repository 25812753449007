export function checkAll() {
    $('#check-all').click(function() {
        $('.user-checkbox').prop('checked', $(this).prop('checked'));
    });

    $('.user-checkbox').click(function() {
        if ($('.user-checkbox:checked').length == $('.user-checkbox').length) {
            $('#check-all').prop('checked', true);
        } else {
            $('#check-all').prop('checked', false);
        }
    });

    $('#send_thorrel_button').click(function() {
        setTimeout(() => {
            $('.user-checkbox, #check-all').prop('checked', false);
            $('#notice-container').html('<div class="alert alert-info">Successfully sent Thorrel invitation emails</div>').fadeIn();
            setTimeout(() => {
                $('#notice-container .alert').fadeOut();
            }, 10000);

        }, 2000);
    });
}
