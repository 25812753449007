export function manageOrganizations() {
  $(document).on('ajax:success', '#organization-members .remove-member', function(event){
    var data = event.detail[0];
    var table_row = $('tr[data-id="member-' + data['id'] + '"]');
    table_row.fadeOut(function(){
      table_row.remove();
      var table_rows = $('#organization-members tbody tr').length;
      $('.member-count').each(function(){
        $(this).text(table_rows);
      });
    });
  });
}
