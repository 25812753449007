export function users() {
  $('select[data-tz-detect=true]').each(function(){
    var jstz = require("jstimezonedetect");
    var tz = jstz.determine().name();
    var item = $(this).find('[data-tzinfo="' + tz + '"]');
    $(this).val(item.val());
  });

  $('select[data-locale]').on('change', function(){
    var locale = $(this).val();
    var url = $(this).data('locale');
    window.location.replace(url + '?locale=' + locale);
  });
}
